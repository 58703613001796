import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiBootstrap,
  DiHtml5,
  DiSublime,
  DiCss3,
  

} from "react-icons/di";
import { SiPytorch, SiTensorflow, SiFirebase,SiEthereum,SiMysql } from "react-icons/si";
import { BiBitcoin } from "react-icons/bi";


function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <CgCPlusPlus />
        
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />

      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiBootstrap />
      </Col>

     
      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiCss3 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiEthereum />
      </Col>
       <Col xs={4} md={2} className="tech-icons">
        <BiBitcoin />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
     
       <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
      </Col>
    </Row>
  );
}

export default Techstack;

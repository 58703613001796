import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
import axios from "axios";
import pdf from "../../Assets/shishirres.pdf";
import { AiOutlineDownload } from "react-icons/ai";

function Resume() {
  const uri = "https://porfolio-backend.vercel.app/ranks/getRanks";
  const [spojRank, upadteSpojRank] = useState(0);
  const [hackerrank, upadteHackerank] = useState(0);
  const [sem, upadateSem] = useState(0);
  const [cgpa, upadteCgpa] = useState(0);

  useEffect(() => {
    axios
      .get(uri)
      .then((res) => {
        upadteSpojRank(res.data.message[0].spojRank);
        upadteHackerank(res.data.message[1].hackerrank);
        upadteCgpa(res.data.message[2].cgpa);
        upadateSem(res.data.message[3].sem);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container fluid className="resume-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
        <Row className="resume">
          <Col md={6} className="resume-left">
            <h3 className="resume-title">Experience</h3>
            <Resumecontent
              title="Xinfin hybrid blockchain network"
              date="October 2020 - December 2020"
              content={[
                "Blockchain developer intern",
                "Developed the Decentralized Applicatons and deployed them on the private network using Xinpay Extension.",
                "Developed the Smart Contracts for testing the company’s compiler Remix-Xinfin network IDE.",
              ]}
            />
            <Resumecontent
              title="Pandora"
              date="January 2021 - February 2021"
              content={[
                "Research and Development intern in DEFI based company",
                " Gained knowledge on non-fungible tokens, re-fungible tokens, IEO’s, ICO’s, dual yield farming, ethitem, compound, synthePx, mulP-collateral DAI.",
                "Task was to research about various technologies and discuss use-case of them.",
              ]}
            />
             <Resumecontent
              title="TechChurators"
              date="February 2021 - april 2021"
              content={[
                "Blockchian subject matter expert",
                "Created several questions in the domain of blockchain (Ethereum,Bitcoin,Hyperledger ) as required by the company.",
                
              ]}
            />
                <Resumecontent
              title="CUI Inc"
              date="May 2021 - June 2021"
              content={[
                "Blockchian advisor intern",
                "Discuused about various technologies that can be implemented in the project.",
                "",
                
              ]}
            />
            <h3 className="resume-title">Extracurricular Activities</h3>
            <Resumecontent
              title="Voluteer in a college fest"
              content={[
                "A College level fest had a turnaround of 3000 students for series of interesPng events designed, organised and lead by a team of students.",
              ]}
            />
            <Resumecontent
              title="Vice-Caption of a School"
              content={[
                "Managed several sports fest and annual meets also pass the recogination of players taking part in sports zonal and further tournaments.",
              ]}
            />
               <Resumecontent
              title="Programming"
              content={[
                "College rank under 20 in geeks for geeks",
              ]}
            />

          </Col>
          <Col md={6} className="resume-right">
            <h3 className="resume-title">Education</h3>
            <Resumecontent
              title="Bachelor of Engineering in Computer Science "
              date="2018 - 2022"
              content={[`CGPA: 7.3 (Till 5th Sem)`]}
            />
            <Resumecontent
              title="St.Basil’s school (Council for Indian School Certificate Examinations) Higher Secondary School (12th Std)."
              date="2015 - 2016"
              content={["Precentage: 86%"]}
            />
            <Resumecontent
              title="St.Basil’s school(Indian Certicate of Secondary Education) High School (10th Std) "
              date="2013 - 2014"
              content={["Precentage: 75%"]}
            />
            <h3 className="resume-title">Training and Certificates</h3>
            <Resumecontent
              title=""
              content={[
                "Blockchain Basics | Coursera | April-2020",
                "Smart Contracts | Coursera | Aug-2021",
                "Decentralised ApplicaPons | Coursera | Sep -2020",
              ]}
            />

             <h3 className="resume-title">Skills</h3>
            <Resumecontent
              title="Programming Languages "
              content={[`C, C++, Java, Basic SQL, Solidity`]}
            />
            <Resumecontent
              title="Technologies."
              
              content={["Bitcoin Blockchain, Ethereum Blockchain, Front-end Development."]}
            />
            <Resumecontent
              title="Frameworks"
              
              content={["Truffle, Bootstrap"]}
            />
             <Resumecontent
              title="Markup Languages"
              
              content={["HTML5, CSS3"]}
            />
             <Resumecontent
              title="IDE"
              
              content={["VS Code, Sublime"]}
            />
             <Resumecontent
              title="Others"
              
              content={[" OOPS, Data Structure, Decentralised applications, Smart Contracts, Web3 Js"]}
            />
            <Resumecontent
              title="Soft Skills"
              
              content={["Critical thinking, Teamwork, Problem-solving skills, Persuasion, Adaptability, Leadership"]}
            />
           
          </Col>
       

           
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
          <AiOutlineDownload />&nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </Container>
  );
}

export default Resume;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.jpeg";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Social Media"
              description="A social media website using Ethereum(Platform),Web3Js (Integration),Solidity(ProgrammingLanguage),Javascript(TestCases),ReactJS, IPFS(InterPlanetaryFileSystem)and Truffle(Environment). "
              link="https://github.com/s860/INSTACLONE"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Cryptocurrency"
              description="Created an ICO (Inital Coin Offering) website and build my own ERC20 Token using Ethereum (Platform) ,Web3Js(Integration),Solidity(ProgrammingLanguage),Javascript(Test cases), ReactJS and Truffle(Environment)."
              link="https://github.com/s860/cryptocurrency"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Pet ecommerce"
              description="Created a PetEcommerce website usingEthereum(Platform), Web3Js(Integration), Solidity(ProgrammingLanguage), Javascript(Test cases), HTML5, CSS3 and Truffle(Environment)."
              link="https://github.com/s860/petshop"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Song playlist"
              description="As part of the self-paced learning through online portals, developed the project based on song playlist using basics of HTML5 and CSS3.."
              link="https://github.com/s860/My-own-playlist-webpage"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Decentralized Media Player"
              description="Created a Decentralized Media Player Website using Ethereum ,Web3Js, Solidity, Javascript, IPFS, MetaMask, Truffle and Ganache."
              link="https://github.com/s860/DVIDEO"
            />
          </Col>

         
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
